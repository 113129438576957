import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { NewsList } from '@mangoart/gatsby-ui/components/ezagrar/NewsList';
import DefaultLayout from '../layout/DefaultLayout';
import PageTitle from '@mangoart/gatsby-ui/components/ezagrar/MeinTraktor-Lamborghini-Same/PageTitle';
import { Icon } from '@mangoart/gatsby-ui/components/ezagrar/Icon';

const NewsPage = ({ data, location }) => {
  const { pageData, siteData, news } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  const { title, icon } = content;
  const displayedNews = {
    edges: news.edges.filter(
      (newsItem) =>
        newsItem.node.title.includes('Aktionsmodell') ||
        newsItem.node.title.includes('Aktion -') ||
        newsItem.node.title.includes('Kundendienst Mitarbeiter'),
    ),
  };
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>
        {icon && <Icon icon={icon} />}
        {title}
      </PageTitle>
      <NewsList data={displayedNews} />
    </DefaultLayout>
  );
};

export default NewsPage;

export const NewsPageQuery = graphql`
  query NewsPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageNews {
      content {
        title
        icon
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
    news: allCockpitNews(
      filter: { sites: { in: "meintraktor.at" }, published: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          id
          title
          teaser
          slug
          date
          customurl
          expirationdate
          teaserimage {
            childImageSharp {
              gatsbyImageData(width: 400, aspectRatio: 1.9)
            }
          }
          file {
            publicURL
          }
          images {
            image {
              childImageSharp {
                gatsbyImageData(width: 400, aspectRatio: 1.9)
              }
            }
          }
        }
      }
    }
  }
`;
