import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './PageTitle.module.css';

PageTitle.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
PageTitle.defaultProps = {
  id: null,
  className: null,
};

function PageTitle({ id, children, className, ...rest }) {
  const classes = classNames(styles.PageTitle, className);

  return (
    <div className={classes} id={id} {...rest}>
      <h2>{children}</h2>
    </div>
  );
}

export default PageTitle;
